<template>
  <div class="bg-white px-8 quotation-create-page">
    <v-form
      ref="createQuotationForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="validateJob()"
    >
      <v-row>
        <v-col md="12">
          <div class="form-action">
            <div class="form-title">
              Quotation for
              <span
                class="form-title-link cursor-pointer"
                v-on:click="openDialog('customer')"
              >
                <template v-if="vCustomerId">{{
                  vCustomer.display_name
                }}</template>
                <template v-else>
                  Select a Customer
                  <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
                </template>
              </span>
            </div>
            <div>
              <v-btn
                :disabled="formLoading"
                v-on:click="goBack()"
                class="custom-bold-button custom-grey-border"
                depressed
                >Cancel</v-btn
              >
              <v-btn
                class="custom-bold-button ml-4"
                depressed
                :disabled="!formValid || formLoading"
                :loading="formLoading"
                v-on:click="validateJob()"
                color="cyan white--text"
              >
                <template v-if="$route?.query?.edit">
                  Update Quotation</template
                >
                <template v-else> Save Quotation </template>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col md="6">
          <label for="visit-title" class="required">Quotation Title</label>
          <TextField
            id="visit-title"
            dense
            filled
            placeholder="Title"
            solo
            flat
            counter="50"
            v-model="dbQuotation.title"
            color="cyan"
            :maxlength="250"
            :rules="[validateRules.required(dbQuotation.title, 'Title')]"
          />
          <TextAreaField
            auto-grow
            dense
            filled
            color="cyan"
            v-model="dbQuotation.description"
            placeholder="Description"
            solo
            flat
            row-height="25"
            counter="250"
          />

          <v-row>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3
                  ><label class="my-0">Billing Location</label></v-flex
                >
                <v-flex md9 class="position-relative">
                  <v-icon
                    v-on:click="openDialog('billing')"
                    small
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBilling?.id">
                <p class="mb-0">
                  <template v-if="vBilling?.property_name">
                    {{ vBilling?.property_name }}<br />
                  </template>
                  {{ vBilling.property_address }}
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select billing location</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('billing-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBillingContact?.id">
                <p class="mb-0">{{ vBillingContact.display_name }}</p>
                <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                <p class="mb-0">{{ vBillingContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout v-if="false" class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="dbQuotation.notify_billing_contact_person"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3><label class="my-0">Site Info</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vProperty?.id">
                <p class="mb-0">
                  <template v-if="vProperty?.property_name">
                    {{ vProperty?.property_name }}<br />
                  </template>
                  {{ vProperty.property_address }}
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select Site Info</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vPropertyContact?.id">
                <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout v-if="false" class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="dbQuotation.notify_property_contact_person"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6">
          <table width="100%" style="table-layout: fixed">
            <tr>
              <td width="20%">
                <label for="quotation-id">Quotation #</label>
              </td>
              <td>
                <v-text-field
                  id="quotation-id"
                  dense
                  filled
                  readonly
                  hide-details
                  placeholder="Quotation #"
                  solo
                  flat
                  :value="dbQuotation.barcode"
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="quotation-rate">Rate opportunity</label>
              </td>
              <td>
                <v-rating
                  id="quotation-rate"
                  v-model.trim="dbQuotation.rating"
                  background-color="orange lighten-3"
                  color="orange"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td>
                <label for="quotation-date">Date</label>
              </td>
              <td>
                <date-picker
                  key="quotation-date"
                  id="quotation-date"
                  :min-date="todayDate"
                  placeholder="Date"
                  v-model="dbQuotation.date"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="sale-by">Sale by</label>
              </td>
              <td>
                <v-autocomplete
                  id="sale-by"
                  dense
                  v-model="dbQuotation.sale_by"
                  item-text="display_name"
                  item-color="cyan"
                  item-value="id"
                  filled
                  :items="userList"
                  hide-details
                  placeholder="Sale by"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="customer-ref">Customer Ref</label>
              </td>
              <td>
                <v-text-field
                  id="customer-ref"
                  dense
                  filled
                  v-model="dbQuotation.customer_ref"
                  hide-details
                  placeholder="Customer Ref"
                  solo
                  flat
                  color="cyan"
                  :maxlength="250"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="your-ref">Your Ref</label>
              </td>
              <td>
                <v-text-field
                  id="your-ref"
                  dense
                  filled
                  v-model="dbQuotation.your_ref"
                  hide-details
                  placeholder="Your Ref"
                  solo
                  flat
                  color="cyan"
                  :maxlength="250"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="quote-by">Quote by</label>
              </td>
              <td>
                <v-autocomplete
                  id="quote-by"
                  dense
                  readonly
                  v-model="dbQuotation.quote_by"
                  item-text="display_name"
                  item-color="cyan"
                  item-value="id"
                  :items="userList"
                  filled
                  hide-details
                  placeholder="Quote by"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <template v-if="!$route?.query?.edit">
              <tr>
                <td>
                  <label for="type">Type</label>
                </td>
                <td>
                  <div class="d-flex ml-1">
                    <v-btn
                      depressed
                      tile
                      :outlined="!(dbQuotation.type == 1)"
                      v-on:click="
                        dbQuotation.type = 1;
                        changeContractStatus();
                      "
                      value="1"
                      color="light-green darken-3 white--text"
                      >New</v-btn
                    >
                    <v-btn
                      class="mx-2"
                      depressed
                      tile
                      :outlined="!(dbQuotation.type == 2)"
                      v-on:click="
                        dbQuotation.type = 2;
                        changeContractStatus();
                      "
                      value="2"
                      color="orange darken-4 white--text"
                      >Maintenance</v-btn
                    >
                  </div>
                </td>
              </tr>
            </template>
            <template
              v-if="
                dbQuotation.schedule_generated == 0 && dbQuotation.type == 2
              "
            >
              <tr>
                <td>
                  <label>Maintenance Type</label>
                </td>
                <td>
                  <div class="d-flex ml-1 flex-wrap">
                    <v-btn
                      v-for="(row, index) in contractRecurringScheduleTypeList"
                      :key="`recurring-schedule-type-list-${index}`"
                      :disabled="formLoading"
                      :loading="formLoading"
                      :color="row.color"
                      depressed
                      tile
                      :class="{
                        'btn-selected': !(
                          dbQuotation?.contract_type == row.value
                        ),
                        '': row.border,
                      }"
                      :text="!(dbQuotation?.contract_type == row.value)"
                      v-on:click="dbQuotation.contract_type = row.value"
                      class="mr-2 mb-1"
                    >
                      {{ row.text }}
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Duration</label>
                </td>
                <td>
                  <div class="d-flex">
                    <div class="w-50 pr-2">
                      <date-picker
                        key="start-date"
                        id="start-date"
                        placeholder="Start Date"
                        v-model="dbQuotation.contract_start"
                      />
                    </div>
                    <div class="w-50 pl-2">
                      <date-picker
                        key="end-date"
                        id="end-date"
                        :min-date="dbQuotation.contract_start"
                        placeholder="End Date"
                        v-model="dbQuotation.contract_end"
                      />
                    </div>
                  </div>
                  <label
                    class="my-2"
                    v-if="
                      dbQuotation?.contract_start && dbQuotation?.contract_end
                    "
                    >This Contract will start on
                    {{ formatDate(dbQuotation.contract_start) }} and finish on
                    {{ formatDate(dbQuotation.contract_end) }}</label
                  >
                </td>
              </tr>
            </template>
          </table>
        </v-col>
        <v-col cols="12" v-if="false" ref="qtContract">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3
                class="font-weight-700 custom-headline color-custom-blue d-flex align-center"
              >
                Contract
                <v-checkbox
                  v-if="false"
                  color="cyan"
                  hide-details
                  class="ml-5 py-0 my-0"
                  :value="contract_status"
                  v-on:change="changeContractStatus()"
                />
              </h3>
            </v-card-title>
            <v-card-text v-if="contract_status" class="p-6 font-size-16">
              <div>
                <recurring-schedule
                  :key="`contract-recurring-schedule-${schedule_key}`"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" ref="qtLineItem">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Line Items
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <line-item is-quotation :quote-type="dbQuotation.type" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" ref="qtTermsConditions">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                TERMS &amp; CONDITIONS
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-autocomplete
                dense
                color="cyan"
                filled
                label="Terms &amp; Conditions"
                solo
                flat
                class="mb-2"
                item-color="cyan"
                item-text="label"
                item-value="id"
                hide-details
                :disabled="formLoading"
                :loading="formLoading"
                :items="termsConditionList"
                v-model="dbQuotation.term_condition_id"
                v-on:change="update_term_condition()"
              />
              <tiny-mce
                :disabled="formLoading"
                v-model="dbQuotation.term_conditions"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" ref="qtNote">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                INTERNAL NOTES &amp; ATTACHMENTS
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-layout>
                <v-flex class="mr-4" md6>
                  <label class="font-size-16 font-weight-600"
                    >Internal Note</label
                  >
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Internal Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="formLoading"
                    :loading="formLoading"
                    :items="adminNoteList"
                    v-model="dbQuotation.admin_note_id"
                    v-on:change="update_admin_note()"
                  >
                  </v-autocomplete>
                  <tiny-mce
                    :disabled="formLoading"
                    v-model="dbQuotation.admin_note"
                  />
                </v-flex>
                <v-flex md6>
                  <label class="font-size-16 font-weight-600"
                    >Client Note
                    <span class="text--secondary font-weight-500"
                      >(visible on PDF)</span
                    ></label
                  >
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Client Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="formLoading"
                    :loading="formLoading"
                    :items="clientNoteList"
                    v-model="dbQuotation.client_note_id"
                    v-on:change="update_client_note()"
                  >
                  </v-autocomplete>
                  <tiny-mce
                    :disabled="formLoading"
                    v-model="dbQuotation.client_note"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-4">
                <v-flex md6 class="mr-4">
                  <label class="font-size-16 font-weight-600"
                    >Attachments</label
                  >
                  <table width="100%">
                    <tbody>
                      <tr
                        v-for="(row, index) in dbQuotation.attachments"
                        :key="`prev-attach-${index}`"
                      >
                        <td width="50%" class="pr-2 py-2">
                          <v-text-field
                            prepend-inner-icon="mdi-attachment mdi-rotate-90"
                            placeholder="File Name"
                            label="File Name"
                            dense
                            solo
                            flat
                            readonly
                            color="cyan"
                            v-model="row.name"
                            hide-details
                          ></v-text-field>
                        </td>
                        <td width="50%" class="pl-2 py-2">
                          <v-text-field
                            placeholder="File Name"
                            label="File Name"
                            dense
                            solo
                            flat
                            color="cyan"
                            v-model="row.name"
                            hide-details
                            class="has-delete-outer-icon"
                            append-outer-icon="mdi-delete"
                            v-on:click:append-outer="
                              remove_row('dbQuotation.attachments', index)
                            "
                            :suffix="`.${row.ext}`"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr v-for="(row, index) in attachments" :key="index">
                        <td width="50%" class="pr-2 py-2">
                          <v-file-input
                            prepend-inner-icon="mdi-attachment mdi-rotate-90"
                            placeholder="Click here to select file"
                            label="Click here to select file"
                            prepend-icon=""
                            color="cyan"
                            solo
                            flat
                            v-model="row.accepted_file"
                            dense
                            v-on:change="update_file_name(index)"
                            hide-details
                          ></v-file-input>
                        </td>
                        <td width="50%" class="pl-2 py-2">
                          <v-text-field
                            placeholder="File Name"
                            label="File Name"
                            dense
                            solo
                            flat
                            color="cyan"
                            v-model="row.file_name"
                            hide-details
                            class="has-delete-outer-icon"
                            append-outer-icon="mdi-delete"
                            v-on:click:append-outer="
                              remove_row('attachments', index)
                            "
                            :suffix="get_file_extension(row.file_type)"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td height="10px" colspan="2"></td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <v-btn
                            color="cyan white--text"
                            small
                            :disabled="formLoading"
                            :loading="formLoading"
                            v-on:click="add_attachment_row()"
                            class="custom-bold-button"
                            >Add More...</v-btn
                          >
                        </td>
                      </tr>
                      <tr>
                        <td height="10px" colspan="2"></td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-4"> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <customer-select :key="`quotation-customer-select-${dialog_key}`" />
    <customer-create :key="`quotation-customer-create-${dialog_key}`" />
    <person-select
      :dialog="contactDialog"
      v-on:close="contactDialog = false"
      :type="contactType"
    />
    <address-select
      :dialog="propertyDialog"
      v-on:close="propertyDialog = false"
      :type="propertyType"
      :label="propertyLabel"
    />
    <prevent-dialog :common-dialog="routePreventDialog" :dialogWidth="600">
      <template v-slot:body>
        <v-layout>
          <v-flex md2 class="text-center">
            <!--begin::Svg Icon-->
            <inline-svg
              width="50"
              :src="$assetURL('media/custom-svg/attention-circle.svg')"
            />
            <!--end::Svg Icon-->
          </v-flex>
          <v-flex md10>
            <p class="font-weight-700">
              Are you sure you want to leave this page?
            </p>
            <v-layout>
              <v-flex>
                <v-btn
                  class="mr-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  small
                  v-on:click="stayOnPage"
                >
                  Stay on this page
                </v-btn>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button"
                  small
                  v-on:click="leavePage"
                >
                  Leave this page
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </prevent-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/components/QDatePicker.vue";
import LineItemV2 from "@/view/pages/partials/Line-Item-V2.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import Dialog from "@/view/pages/partials/Dialog";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import {
  SET_DIALOG_STATUS,
  CLEAR_CUSTOMER,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
} from "@/core/services/store/customer.module";
import {
  UPLOAD,
  QUERY,
  POST,
  PATCH,
} from "@/core/services/store/request.module";
import {
  SET_RECURRING_DATE,
  CLEAR_CONTRACT_STATE,
} from "@/core/services/store/contract.module";
import {
  SET_UOM,
  SET_GST,
  SET_EDIT_LINE_ITEM,
  SET_EDIT_CALCULATION,
  CLEAR_LINE_ITEM,
} from "@/core/services/store/line.item.module";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import RecurringSchedule from "@/view/pages/quotation/Contract/RecurringSchedule.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-quotation",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      schedule_key: Number(new Date()),
      dialog_key: Number(new Date()),
      forcePush: false,
      quotationCreated: false,
      routePreventDialog: false,
      formLoading: false,
      formValid: true,
      dbQuotation: {
        barcode: null,
        title: null,
        description: null,
        contract_type: "onetime",
        billing_contact_person: false,
        property_contact_person: false,
        rating: 1,
        type: 1,
        date: null,
        contract_start: moment().format("YYYY-MM-DD"),
        contract_end: moment().add(1, "year").format("YYYY-MM-DD"),
        sale_by: null,
        customer_ref: null,
        your_ref: null,
        quote_by: null,
        schedule_generated: 0,
        term_condition_id: 0,
        term_conditions: null,
        admin_note_id: 0,
        admin_note: null,
        client_note_id: 0,
        client_note: null,
        attachments: [],
      },
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      termsConditionList: [],
      userList: [],
      todayDate: null,
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
      contract_status: false,
    };
  },
  components: {
    "customer-select": CustomerSelect,
    "customer-create": CustomerCreate,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    "recurring-schedule": RecurringSchedule,
    "line-item": LineItemV2,
    "date-picker": DatePicker,
    "tiny-mce": TinyMCE,
    "prevent-dialog": Dialog,
  },
  methods: {
    stayOnPage() {
      PreventDialogEventBus.$emit("stay-on-page", true);
    },
    leavePage() {
      PreventDialogEventBus.$emit("leave-page", true);
    },
    changeContractStatus() {
      this.schedule_key = Number(new Date());
      this.$nextTick(() => {
        this.contract_status = this.dbQuotation.type == 2;
      });
    },
    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_term_condition() {
      let output = this.lodash.find(this.termsConditionList, {
        id: this.dbQuotation.term_condition_id,
      });
      if (output) {
        this.dbQuotation.term_conditions = output.description;
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbQuotation.admin_note_id,
      });
      if (output) {
        this.dbQuotation.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.dbQuotation.client_note_id,
      });
      if (output) {
        this.dbQuotation.client_note = output.description;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    openDialog(type) {
      this.dialog_key = Number(new Date());
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Info";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Site Info");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Site Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }

      if (!this.lSelectedLineItem || !this.lSelectedLineItem.length) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      //title validation

      for (var k = 0; k < this.lSelectedLineItem?.length; k++) {
        if (!this.lSelectedLineItem[k].product) {
          ErrorEventBus.$emit("update:error", `Line Item title is required.`);
          return false;
        }
      }

      const validateStatus = this.$refs.createQuotationForm.validate();

      const formErrors = this.validateForm(this.$refs.createQuotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      this.formLoading = true;

      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbQuotation,
        /*contract: {
          dates: this.contractScheduleOutput,
          schedule: this.contractRecurringSchedule
        },*/
        attachments: [...this.dbQuotation.attachments, ...new_attachments],
        // is_contract: + this.contract_status,
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        "line-item": this.lSelectedLineItem,
        calculation: this.lCalculation,
      };

      let requestType = POST;
      let requestURL = "quotation-v1";

      let quotationId = this.$route?.query?.edit ?? 0;

      if (quotationId) {
        requestType = PATCH;
        requestURL = `quotation-v1/${quotationId}`;
      }

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(({ data }) => {
          this.$store.dispatch(CLEAR_CUSTOMER);
          this.$store.dispatch(CLEAR_LINE_ITEM);
          this.forcePush = true;
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute("quotation.detail", {
                params: { id: data?.id },
              })
            );
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },
    get_attributes() {
      this.$store
        .dispatch(QUERY, {
          url: "quotation/options",
          data: { quotation: ObjectPath.get(this.$route, "query.edit") },
        })
        .then((response) => {
          this.dbQuotation.barcode = ObjectPath.get(response, "data.barcode");

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Create" },
            { title: this.dbQuotation.barcode },
          ]);

          this.$store.dispatch(
            SET_UOM,
            ObjectPath.get(response, "data.uom_list", [])
          );
          this.$store.dispatch(
            SET_GST,
            ObjectPath.get(response, "data.entity.gstvalue", 0)
          );
          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list",
            []
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list",
            []
          );
          this.termsConditionList = ObjectPath.get(
            response,
            "data.term_condition_list",
            []
          );

          let defaultTermConditionObject = this.lodash.find(
            this.termsConditionList,
            {
              primary: 1,
            }
          );
          if (defaultTermConditionObject) {
            this.dbQuotation.term_condition_id = defaultTermConditionObject.id;
            this.dbQuotation.term_conditions =
              defaultTermConditionObject.description;
          }

          let defaultClientObject = this.lodash.find(this.clientNoteList, {
            primary: 1,
          });
          if (defaultClientObject) {
            this.dbQuotation.client_note_id = defaultClientObject.id;
            this.dbQuotation.client_note = defaultClientObject.description;
          }

          let defaultAdminObject = this.lodash.find(this.adminNoteList, {
            primary: 1,
          });
          if (defaultAdminObject) {
            this.dbQuotation.admin_note_id = defaultAdminObject.id;
            this.dbQuotation.admin_note = defaultAdminObject.description;
          }

          this.userList = ObjectPath.get(response, "data.sales_users", []);

          this.dbQuotation.sale_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );
          this.dbQuotation.quote_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          let dbQuotation = ObjectPath.get(response, "data.quotation");

          if (dbQuotation?.id) {
            this.dbQuotation.barcode = dbQuotation?.barcode;

            this.dbQuotation.title = dbQuotation?.title;
            this.dbQuotation.description = dbQuotation?.description;
            this.dbQuotation.customer_ref = dbQuotation?.customer_ref;
            this.dbQuotation.contract_type = dbQuotation?.contract_type;
            this.dbQuotation.your_ref = dbQuotation?.your_ref;
            this.dbQuotation.quote_by = dbQuotation?.quote_by;
            this.dbQuotation.sale_by = dbQuotation?.sale_by;
            this.dbQuotation.schedule_generated =
              dbQuotation?.schedule_generated;
            this.dbQuotation.type = dbQuotation?.type;
            this.dbQuotation.date = moment(dbQuotation?.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.contract_start = dbQuotation?.contract_start
              ? moment(dbQuotation.contract_start).format("YYYY-MM-DD")
              : null;
            this.dbQuotation.contract_end = dbQuotation?.contract_end
              ? moment(dbQuotation.contract_end).format("YYYY-MM-DD")
              : null;
            this.dbQuotation.rating = dbQuotation?.rating;
            this.dbQuotation.attachments = dbQuotation?.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              dbQuotation?.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              dbQuotation?.notify_property_contact_person;

            this.dbQuotation.client_note_id = dbQuotation?.client_note_id;
            this.dbQuotation.client_note = dbQuotation?.client_note;

            this.dbQuotation.term_condition_id = dbQuotation?.term_condition_id;
            this.dbQuotation.term_conditions = dbQuotation?.term_conditions;

            this.dbQuotation.admin_note_id = dbQuotation?.admin_note_id;
            this.dbQuotation.admin_note = dbQuotation?.admin_note;

            this.$store.commit(
              SET_RECURRING_DATE,
              dbQuotation?.contract_schedule
            );

            this.$store.commit(SET_CUSTOMER, dbQuotation?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbQuotation?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbQuotation?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, dbQuotation?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbQuotation?.billing_contact_person_relation
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              additional_rows: dbQuotation?.additional_rows ?? [],
              ctx_discount_label: dbQuotation?.ctx_discount_label ?? null,
              ctx_discount_value: dbQuotation?.ctx_discount_value ?? 0,
              ctx_discount_type: dbQuotation?.ctx_discount_type ?? 1,
              discount_type: dbQuotation?.discount_type ?? 1,
              discount_value: dbQuotation?.discount_value ?? 0,
              tax_active: dbQuotation?.tax_active ?? false,
              adjustment: dbQuotation?.adjustment ?? 0,
              show_price: true,
              discount_level: dbQuotation?.discount_level ?? "transaction",
            });

            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              dbQuotation?.line_item ?? []
            );

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Quotation", route: "quotation" },
              { title: "Update" },
              { title: this.dbQuotation.barcode },
            ]);
          }
        })
        .catch((error) => {
          this.logError(error);
          this.forcePush = true;
          this.goBack();
        })
        .finally(() => {
          // this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.get_attributes();

    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  destroyed() {
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(CLEAR_LINE_ITEM);
    this.$store.commit(CLEAR_CONTRACT_STATE);
    PreventDialogEventBus.$off("stay-on-page");
    PreventDialogEventBus.$off("leave-page");
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbQuotation.date = this.todayDate;
    this.dbQuotation.enquiry = this.lodash.toSafeInteger(
      ObjectPath.get(this.$route, "query.enquiry")
    );
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  computed: {
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "lSelectedLineItem",
      "lCalculation",
      "contractRecurringScheduleTypeList",
      "contractScheduleOutput",
      "contractRecurringSchedule",
    ]),
  },
  beforeRouteLeave(to, from, next) {
    if (this.forcePush) {
      next();
    } else if (this.quotationCreated) {
      next();
    } else {
      this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", () => {
        this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", () => {
        this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;
    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
